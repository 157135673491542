import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { SubmitHandler, FormHandles, FormProps } from '@unform/core';
import { Form } from '@unform/web';
import qs from 'qs';
import useSWR from 'swr';

import api from '../../../services/api';
import Input from '../../Input';
import DatePicker from '../../InputDatePicker';
import Select from '../../InputSelect';
// import Input from './components/Input';

interface FormData {
  status: string | number;
  ordens_servicos_tipos_id: string | number;
  tecnico_id: string | number;
  data_agendamento_inicial: string;
  data_agendamento_final: string;
  data_abertura_inicial: string;
  data_abertura_final: string;
}

interface Props {
  initialData?: Record<string, any>;
  onSubmit: SubmitHandler;
  ref?: any;
  activeData?: any;
}

type IFormProps = FormProps & Props;

const initialDataForm = {
  status: '',
  ordens_servicos_tipos_id: '',
  tecnico_id: '',
  prioridade: '',
  data_abertura_inicial: '',
  data_abertura_final: '',
  data_agendamento_inicial: '',
  data_agendamento_final: '',
};
const FormOS: React.FC<Props> = ({
  initialData = initialDataForm,
  onSubmit,
  activeData,
}: // ref,
Props) => {
  // const [formData, setFormData] = useState<FormData | any>([]);
  const formRef = useRef<FormHandles>(null);
  //  ref = formRef;

  // const handleSubmit: SubmitHandler<FormData> = data => {
  //   const dt = onSubmit();
  //   setFormData(onSubmit());
  // };
  const handleReset = () => {
    // console.log(formRef, data, qs.stringify(data));
    console.log('Reset Form');
    formRef?.current?.reset();
    formRef?.current?.setData({ initialDataForm, ...activeData });
    formRef?.current?.submitForm();
  };

  useEffect(() => {
    formRef?.current?.reset();
    formRef?.current?.setData({ initialDataForm, ...initialData });
    // formRef?.current?.submitForm();
  }, [initialData]);

  const { data: tecnicos = [] } = useSWR(
    `${process.env.REACT_APP_API_URL}/boardos/export/usuarios?select=id,nome&per_page=100&order_by=habilitado DESC, nome ASC`,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fetcher: (resource, init) =>
        api.get(resource, { ...init }).then(res => res.data.data),
    }
  );
  const { data: os_tipos = [] } = useSWR(
    `${process.env.REACT_APP_API_URL}/boardos/export/os_tipos`,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fetcher: (resource, init) =>
        api.get(resource, { ...init }).then(res => res.data),
    }
  );

  const statusOS = [
    { label: 'Aberta', value: 0 },
    { label: 'Fechada', value: 1 },
    { label: 'Executada', value: 2 },
    { label: 'Cancelada', value: 3 },
    { label: '(Aberta|Executada)', value: -2 },
    { label: '(Fechada|Cancelada)', value: -3 },
  ];

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      // initialData={formData}
      className="col"
      // className="d-flex"
    >
      <div className="row">
        <div className="col-sm-10">
          <fieldset className="row">
            <Select
              name="tecnico_id"
              placeholder="Técnicos"
              label="Técnicos"
              // value="131"
              // isMulti
              // defaultValue={[{ value: 0, label: '(Todos)' }]}
              //   options={[
              //     { label: '(Todos)', value: '', isFixed: true },
              //     { label: '(Nenhum)', value: 0 },
              //     ...tecnicos?.map((item: any) => ({
              //       label: item.nome,
              //       value: item.id,
              //     })),
              //   ]}
              // />
            >
              {[
                { label: '(Todos)', value: '', isFixed: true },
                { label: '(Nenhum)', value: 0 },
                ...tecnicos?.map((item: any) => ({
                  label: item.nome,
                  value: item.id,
                })),
              ].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <Select
              name="ordens_servicos_tipos_id"
              placeholder="Tipo de OS"
              label="Tipo de OS"
              // defaultValue="6"
              // isMulti
              // options={[
              //   { label: '(Todos)', value: '' },
              //   ...os_tipos?.map((item: any) => ({
              //     label: item.nome,
              //     value: item.id,
              //   })),
              // ]}
              // />
            >
              {[
                { label: '(Todos)', value: '' },
                ...os_tipos?.map((item: any) => ({
                  label: item.nome,
                  value: item.id,
                })),
              ].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <Select
              name="status"
              placeholder="Status"
              // isMulti
              label="Status"
              //   options={[
              //     { label: '(Todos)', value: '' },
              //     ...statusOS?.map((item: any) => ({
              //       label: item.label,
              //       value: item.value,
              //     })),
              //   ]}
              // />
            >
              {[
                { label: '(Todos)', value: '' },
                ...statusOS?.map((item: any) => ({
                  label: item.label,
                  value: item.value,
                })),
              ].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </fieldset>
          <div className="row">
            <fieldset>
              <legend style={{ fontSize: 11 }}>Data de Abertura</legend>
              <Input
                name="data_abertura_inicial"
                type="datetime-local"
                step="1"
                label="de&nbsp;"
                placeholder="Data de Abertura Inicial"
              />{' '}
              <Input
                name="data_abertura_final"
                type="datetime-local"
                step="1"
                placeholder="Data de Abertura Final"
                label="a&nbsp;"
              />
            </fieldset>
            <fieldset>
              <legend style={{ fontSize: 11 }}>Data de Agendamento</legend>
              <Input
                name="data_agendamento_inicial"
                type="datetime-local"
                step="1"
                placeholder="Data de Agendamento Inicial"
                label="de&nbsp;"
              />{' '}
              <Input
                name="data_agendamento_final"
                type="datetime-local"
                step="1"
                placeholder="Data de Agendamento Final"
                label="a&nbsp;"
              />
            </fieldset>
            {/* <DatePicker
        name="data_abertura_inicial"
        placeholderText="Data de Abertura Inicial"
        dateFormat="d/M/yyyy HH:mm"
        timeFormat="HH:mm"
        // timeIntervals={1}
        showTimeInput
      />  <DatePicker
        name="data_abertura_final"
        placeholderText="Data de Abertura Final"
      />
      <DatePicker
        name="data_agendamento_inicial"
        placeholderText="Data de Agendamento Inicial"
      />
      <DatePicker
        name="data_agendamento_final"
        placeholderText="Data de Agendamento Final"
      /> */}
          </div>
        </div>
        <div className="col-sm-2">
          <Button type="button" onClick={handleReset} variant="outline-danger">
            Reset
          </Button>
          <Button type="submit" variant="outline-success">
            Filtrar
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default FormOS;
