import React, { useState } from 'react';
import SplitPane from 'react-split-pane';

import { startOfToday, endOfToday, formatISO9075, add, sub } from 'date-fns';
import qs from 'qs';
import useSWR from 'swr';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import SearchBar from '../../components/SearchBar';
// import BoardOS from './BoardOS';
import BoardOSGroupBy from './BoardOSGroupBy';
// import BoardOSTecnicos from './BoardOSTecnicos';
// import BoardOSTipo from './BoardOSTipo';
import MapCTO from './MapCTO';
import MapOS from './MapOS';

function BoardOSDashBoard() {
  const hojeInicio = formatISO9075(startOfToday());
  const hojeFim = formatISO9075(endOfToday());

  const ontemInicio = formatISO9075(
    sub(startOfToday(), {
      days: 1,
    })
  );
  const ontemFim = formatISO9075(
    sub(endOfToday(), {
      days: 1,
    })
  );
  const amanhaInicio = formatISO9075(
    add(startOfToday(), {
      days: 1,
    })
  );
  const amanhaFim = formatISO9075(
    add(endOfToday(), {
      days: 1,
    })
  );

  const [panelList, setPanelList] = useState([
    {
      name: 'OS do Dia',
      key: 'BoardOSTecnicosDia',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      fieldGroup: 'tecnico_id',
      fieldLaneTitle: 'tecnico_nome',
      fieldCardTitle: 'ordens_servicos_tipo',
      active: true,
      filterFixed: {
        // tecnico_id: 131,
        data_agendamento_inicial: hojeInicio,
        data_agendamento_final: hojeFim,
        order_by: 'tecnico_nome,data_hora_agendamento ASC',
      },
    },
    {
      name: 'OS de Amanhã',
      key: 'BoardOSTecnicosAmanha',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      active: false,
      fieldGroup: 'tecnico_id',
      fieldLaneTitle: 'tecnico_nome',
      fieldCardTitle: 'ordens_servicos_tipo',
      filterFixed: {
        data_agendamento_inicial: amanhaInicio,
        data_agendamento_final: amanhaFim,
        order_by: 'tecnico_nome,data_hora_agendamento ASC',
      },
    },
    {
      name: 'OS de Ontem',
      key: 'BoardOSTecnicosOntem',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      active: false,
      fieldGroup: 'tecnico_id',
      fieldLaneTitle: 'tecnico_nome',
      fieldCardTitle: 'ordens_servicos_tipo',
      filterFixed: {
        data_agendamento_inicial: ontemInicio,
        data_agendamento_final: ontemFim,
        order_by: 'tecnico_nome,data_hora_agendamento ASC',
      },
    },
    {
      name: 'OS por Técnico',
      key: 'BoardOSTecnicos',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      active: false,
      fieldGroup: 'tecnico_id',
      fieldLaneTitle: 'tecnico_nome',
      fieldCardTitle: 'ordens_servicos_tipo',
      filterFixed: {
        order_by: 'FIELD(status, 0,2,3,1),tecnico_nome, id DESC',
        per_page: 500,
      },
    },
    // {
    //   name: 'OS por Status',
    //   component: BoardOS,
    //   componentName: 'BoardOS',
    //   viewMode: [
    //     { type: 'Kanban', component: BoardOSGroupBy, active: true },
    //     { type: 'Map', component: MapOS, active: false },
    //   ],
    //   key: 'BoardOS',
    //   active: false,
    //   fieldGroup: 'status_id',
    //   fieldLaneTitle: 'tecnico_nome',
    //   fieldCardTitle: 'ordens_servicos_tipo',
    //   filterFixed: {
    //     per_page: 500,
    //     order_by: 'FIELD(status, 0,2,3,1), id DESC',
    //   },
    // },
    {
      name: 'OS por Tipo',
      key: 'BoardOSTipo',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      active: false,
      fieldGroup: 'ordens_servicos_tipos_id',
      fieldLaneTitle: 'ordens_servicos_tipo',
      fieldCardTitle: 'tecnico_nome',
      filterFixed: {
        order_by: 'FIELD(status, 0,2,3,1), data_hora_abertura DESC',
        per_page: 500,
      },
    },
    {
      name: 'OS por Tipo - Sem Agendamento',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      key: 'BoardOSTipoSemAgendamento3dias',
      active: false,
      fieldGroup: 'ordens_servicos_tipos_id',
      fieldLaneTitle: 'ordens_servicos_tipo',
      fieldCardTitle: 'nome',
      filterFixed: {
        status: 0,
        tecnico_id: 0,
        // data_abertura_final: '2026-00-00 00:00:00',
        data_agendamento_final: '0000-00-00 00:00:00',
        order_by: 'FIELD(status, 0,2,3,1), data_hora_abertura DESC',
        per_page: 500,
      },
    },
    {
      name: 'OS por Tipo - Sem Agendamento -3 dias',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      key: 'BoardOSTipoSemAgendamento3dias',
      active: false,
      fieldGroup: 'ordens_servicos_tipos_id',
      fieldLaneTitle: 'ordens_servicos_tipo',
      fieldCardTitle: 'nome',
      filterFixed: {
        status: 0,
        tecnico_id: 0,
        data_abertura_inicial: formatISO9075(
          sub(startOfToday(), {
            days: 3,
          })
        ),
        // data_abertura_final: '2025-00-00 00:00:00',
        // data_agendamento_inicial: '0000-00-00 00:00:00',
        data_agendamento_final: '0000-00-00 00:00:00',
        order_by: 'FIELD(status, 0,2,3,1), data_hora_abertura DESC',
        per_page: 500,
      },
    },
    {
      name: 'OS por Bairro',
      component: BoardOSGroupBy,
      componentName: 'BoardOSGroupBy',
      viewMode: [
        { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapOS, active: true },
      ],
      key: 'BoardOSGroupByBairro',
      active: false,
      fieldGroup: 'bairro',
      fieldLaneTitle: 'bairro',
      fieldCardTitle: 'ordens_servicos_tipo',
      filterFixed: {
        status: -2,
        order_by: 'FIELD(status, 0,2,3,1),bairro,ordens_servicos_tipo, id DESC',
        per_page: 500,
      },
    },
    // {
    //   name: 'Mapa OS',
    //   component: MapOS,
    //   componentName: 'MapOS',
    //   filterFixed: {
    //     status: -2,
    //     order_by: 'FIELD(status, 0,2,3,1), id DESC',
    //     per_page: 500,
    //   },
    // },
    // {
    //   name: 'Mapa OS do Dia',
    //   component: MapOS,
    //   componentName: 'MapOSDia',
    //   key: 'MapOSDia',
    //   active: false,
    //   filterFixed: {
    //     data_agendamento_inicial: hojeInicio,
    //     data_agendamento_final: hojeFim,
    //     order_by: 'data_hora_agendamento ASC',
    //   },
    // },
    // {
    //   name: 'Mapa OS de Amanhã',
    //   component: MapOS,
    //   componentName: 'MapOS',
    //   key: 'MapOSAmanha',
    //   active: false,
    //   filterFixed: {
    //     data_agendamento_inicial: amanhaInicio,
    //     data_agendamento_final: amanhaFim,
    //     order_by: 'data_hora_agendamento ASC',
    //   },
    // },
    // {
    //   name: 'Mapa OS de Ontem',
    //   component: MapOS,
    //   componentName: 'MapOS',
    //   key: 'MapOSOntem',
    //   active: false,
    //   filterFixed: {
    //     data_agendamento_inicial: ontemInicio,
    //     data_agendamento_final: ontemFim,
    //     order_by: 'data_hora_agendamento ASC',
    //   },
    // },
    {
      name: 'Mapa de CTO',
      component: MapCTO,
      componentName: 'MapCTO',
      viewMode: [
        // { type: 'Kanban', component: BoardOSGroupBy, active: true },
        { type: 'Map', component: MapCTO, active: true },
      ],
      key: 'MapCTO',
      active: false,
    },
  ]);

  const [activeBoard, setActiveBoard] = useState(
    () => panelList.filter(p => p.active)[0]
  );
  // const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState(activeBoard.filterFixed);

  // console.log('searchQuery: ', searchQuery, activeBoard.filterFixed);

  const { data: ordensservicos = [] } = useSWR(
    `/boardos/export/os?` + qs.stringify({ per_page: 500, ...searchQuery }),
    {
      revalidateOnFocus: true,
      refreshInterval: 25000,
    }
  );

  const { data: cto = [] } = useSWR(`/boardos/export/cto?`, {
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    // // revalidateOnMount: false,
    // refreshInterval: 0,
  });

  function handleSwitchBoard(board: any) {
    const newPanelList = panelList.map((p: any) => ({
      ...p,
      active: p.key === board.key || false,
    }));
    console.log(newPanelList);
    setSearchQuery(board.filterFixed);
    setPanelList(newPanelList);
    setActiveBoard(() => board);
  }

  function handleSwitchView(view: any) {
    // setActiveBoard({
    //   ...activeBoard,
    //   viewMode: activeBoard.viewMode.map((vm: any) => {
    //     console.log('aqui', activeBoard, {
    //       ...vm,
    //       active: vm === view ? !view.active : vm.active,
    //     });
    //     return {
    //       ...vm,
    //       active: vm.type === view.type ? !vm.active : vm.active,
    //     };
    //   }),
    // });
  }

  return (
    <div className="container-dsboard">
      <SearchBar
        // data={ordensservicos}
        panels={panelList}
        handleSwitchBoard={handleSwitchBoard}
        handleSwitchView={handleSwitchView}
        // resultList={setSearchResults}
        searchQuery={[searchQuery, setSearchQuery]}
      />

      {/* {activeBoard.component({
        data: activeBoard.component === MapCTO ? cto : ordensservicos,
        boardConfig: activeBoard,
      })} */}

      <SplitPane
        split="vertical"
        minSize={
          activeBoard.viewMode.filter(view => view.active).length > 1
            ? '60%'
            : '100%'
        }
        allowResize={true}
        // primary="second"
        // defaultSize={parseInt(
        //   activeBoard.viewMode.filter(view => view.active).length > 1
        //     ? `${localStorage.getItem(`${activeBoard.key}.splitPos`)}`
        //     : '100%',
        //   10
        // )}
        // onChange={size =>
        //   localStorage.setItem(`${activeBoard.key}.splitPos`, `${size}`)
        // }
        style={{ position: 'unset', flexGrow: 1 }}
        pane1Style={{
          display: 'flex',
          flexFlow: 'column',
        }}
        pane2Style={{
          display: 'flex',
          flexFlow: 'column',
          // display: 'none',
        }}
      >
        {activeBoard.viewMode
          .filter(view => view.active)
          .map(viewAtive =>
            viewAtive.component({
              data: activeBoard.component === MapCTO ? cto : ordensservicos,
              boardConfig: activeBoard,
            })
          )}
      </SplitPane>
    </div>
  );
}

export default BoardOSDashBoard;
