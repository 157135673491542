import React from 'react';

import { SWRConfig } from 'swr';

import { AuthProvider } from './contexts/auth';
import Routes from './routes';
import api from './services/api';
import GlobalStyle from './styles/global';

const App: React.FC = () => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        fetcher: (resource, init) =>
          api.get(resource, { ...init }).then(res => res.data),
      }}
    >
      <AuthProvider>
        <Routes />
        <GlobalStyle />
      </AuthProvider>
    </SWRConfig>
  );
};

export default App;
