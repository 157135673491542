import React, { useEffect, useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import api from '../../services/api';
// import { Container } from './styles';

const InputAnotacao = ({
  id,
  anotacao: anotacaoAgendamento,
}: {
  id: number;
  anotacao: string;
}) => {
  const [anotacao, setAnotacao] = useState(anotacaoAgendamento || null);
  useEffect(() => setAnotacao(anotacaoAgendamento), [anotacaoAgendamento]);
  const handleAddAnotacao = () => {
    const anotacaoNova = prompt(
      'Adicionar Anotacao',
      anotacaoAgendamento?.replace('[AGENDAMENTO] ', '')
    );
    if (anotacaoNova && anotacao !== '[AGENDAMENTO] ' + anotacaoNova) {
      // submete apiProxy novaanotacao

      const options: AxiosRequestConfig = {
        method: 'POST',
        url: `/ispbox-proxy/ordensservicos/novaanotacao`,
        headers: {
          // 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'x-requested-with': 'XMLHttpRequest',
          // Authorization: 'Basic *****',
        },
        auth: {
          username: process.env.REACT_APP_ISPBOX_ADMIN_USERNAME || 'demo',
          password: process.env.REACT_APP_ISPBOX_ADMIN_PASSWORD || 'demo',
        },
        data: { anotacao: '[AGENDAMENTO] ' + anotacaoNova, id },
      };

      api
        .request(options)
        .then(function (response) {
          setAnotacao('[AGENDAMENTO] ' + anotacaoNova);
          alert('✅ Adicionado anotacao');
          console.log(response.data);
        })
        .catch(function (error) {
          alert('❌ ' + error);
          console.error(error);
        });
    }
  };
  return (
    <span onClick={handleAddAnotacao} style={{ cursor: 'pointer' }}>
      {anotacao?.replace('[AGENDAMENTO]', '') || '[Adicionar]'}
    </span>
  );
};

export default InputAnotacao;
