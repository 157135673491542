import React from 'react';

import useSWR from 'swr';

const InternetOnline = ({
  serviceId,
}: {
  serviceId: number;
}): React.ReactNode => {
  const { data: serviceOnline, error } = useSWR(
    `/ispbox/servico-internet/${serviceId}/status`,
    {
      // revalidateOnFocus: true,
      refreshInterval: 0,
      errorRetryInterval: 100,
      // errorRetryCount: 1,
      shouldRetryOnError: false,
    }
  );

  return (
    <>
      {serviceOnline && !error ? (
        serviceOnline.length > 0 ? (
          <span className="uptime">{serviceOnline[0]?.tempo}</span>
        ) : (
          <span style={{ color: 'red' }}>Offline</span>
        )
      ) : (
        <span>Carregando...</span>
      )}
    </>
  );
};

export default InternetOnline;
