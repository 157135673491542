/**
 * Parse a string containing a latitude, longitude pair and return them as an array.
 *
 * @param {String} Str
 * @param {Object} Obj
 * @return {Array|Object}
 * @api public
 */

function toLatLng(str, obj = false) {
  const match = /^\s*?(-?[0-9]+\.?[0-9]+?)\s*[, ]\s*(-?[0-9]+\.?[0-9]+?)\s*$/.exec(
    str
  );

  if (match && match.length === 3) {
    const lat = parseFloat(match[1]);
    const lng = parseFloat(match[2]);

    if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
      if (obj) return { lat, lng };

      return [lat, lng];
    }

    return null;
  }

  return null;
}
module.exports = toLatLng;
