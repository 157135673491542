import React, { PropsWithChildren, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import Header from '../../../components/Header';
import { Wrapper } from './styles';

type Props = {
  children: React.ReactNode;
};
const DefaultLayout: React.FC<Props & RouteComponentProps> = ({
  children,
}: React.PropsWithChildren<Props> & RouteComponentProps) => {
  const chatbotScript = `
  window.embeddedChatbotConfig = {
    chatbotId: "Hbkq_-FpXMQ6FMk1kz0CB",
    domain: "www.chatbase.co"
  };
  var script = document.createElement("script");
  script.src = "https://www.chatbase.co/embed.min.js";
  script.chatbotId = "Hbkq_-FpXMQ6FMk1kz0CB";
  script.domain = "www.chatbase.co";
  script.defer = true;
  document.body.appendChild(script);
`;

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = chatbotScript;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Wrapper>
      {/* <Header /> */}
      {children}
    </Wrapper>
  );
};

export default DefaultLayout;
