import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import api from '../../services/api';
import logging from '../../util/logging';

const apiReagendarOS = async data => {
  function formatarData(dt: string) {
    if (dt.match('^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/-]d{4}$'))
      return dt;

    const dataParts = dt.split('-');
    const dataFormatada =
      dataParts[2] + '/' + dataParts[1] + '/' + dataParts[0];

    return dataFormatada;
  }

  const newData = {
    ...data,
    data_agendamento:
      data.data_agendamento !== '0000-00-00'
        ? formatarData(data.data_agendamento)
        : '',
    data_atendimento:
      data.data_atendimento !== '0000-00-00'
        ? formatarData(data.data_atendimento)
        : '',
    hora_agendamento: data.hora_agendamento.substring(0, 5),
  };

  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `/ispbox-proxy/ordensservicos/reagendar`,
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'x-requested-with': 'XMLHttpRequest',
      // Authorization: 'Basic *****',
    },
    auth: {
      username: process.env.REACT_APP_ISPBOX_ADMIN_USERNAME || 'demo',
      password: process.env.REACT_APP_ISPBOX_ADMIN_PASSWORD || 'demo',
    },
    data: qs.stringify(newData),
  };

  return api
    .request(options)
    .then(function (response) {
      logging.info(
        `✅ Ordem de Serviço ${data.id} atualizada response: ${response.data.erro}`,
        'apiReagendarOS'
      );
      logging.info(newData, 'apiReagendarOS');
      return response.data;
    })
    .catch(function (error) {
      logging.error(error);
    });
};

export default apiReagendarOS;
