import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import { AxiosRequestConfig } from 'axios';
import { divIcon } from 'leaflet';
import useSWR from 'swr';

import api from '../../../services/api';
import toLatLng from '../../../util/parse-coordinates';

const MapLayerVeiculos = () => {
  const options: AxiosRequestConfig = {
    headers: {
      Authorization: `${process.env.REACT_APP_ROTAEXATA_APIKEY}`,
    },
  };

  const fetcher = (url: string) =>
    api.get(url, options).then(res => res.data.data);

  const { data: getVeiculos, error } = useSWR(
    `https://api.rotaexata.com.br/ultima-posicao/todos`,
    fetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
      errorRetryInterval: 1000,
      // errorRetryCount: 1,
      shouldRetryOnError: false,
    }
  );

  const markers = getVeiculos?.map(({ posicao: marker }: { posicao: any }) => {
    // const last_position = getUltimaPosicaoNaoVazia(marker.gps);

    const marker_position: any = toLatLng(
      `${marker?.latitude}, ${marker?.longitude}`
    );

    return (
      marker_position && (
        <Marker
          icon={divIcon({
            html: renderToStaticMarkup(
              <i
                className={`fa ${marker.adesao.ico_tipo || 'fa-car'} fa-3x`}
                style={{
                  color: marker.adesao.ico_cor
                    ? `#${marker.adesao.ico_cor}`
                    : '#329d9d',
                  // border: '3px solid red',
                  // textShadow: ' 2px 2px 4px #f0ad4e',
                }}
              />
            ),
          })}
          key={`VeiculoGPS_${marker._id}`}
          position={marker_position}
          // title={`Técnico ${marker.id}`}
          alt={`Veículo ${marker.adesao.vei_placa}`}
        >
          <Tooltip>
            {marker.adesao.vei_placa} - {marker.adesao.vei_descricao}
          </Tooltip>
          <Popup>
            <strong>
              <span className="float-left">
                {marker.adesao.vei_placa} - {marker.adesao.vei_descricao}{' '}
              </span>
              <span className="float-right">
                <i className="fa fa-car-battery"></i>{' '}
                {marker.tensao_bateria_veiculo}V
              </span>
            </strong>
            <br />
            <small>
              {[
                marker.adesao.marca.marca,
                marker.adesao.modelo.modelo,
                marker.adesao.vei_ano,
              ].join(' - ')}
            </small>
            <p>
              Última atualização:{' '}
              {new Intl.DateTimeFormat('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              }).format(new Date(marker.dt_posicao))}
              <br />
              Coordenadas:{' '}
              <a
                href={`http://www.google.com/maps/place/${marker.latitude},${marker.longitude}`}
                target="blank"
              >
                ({marker.latitude},{marker.longitude})
              </a>
              <br />
              {/* Status:{' '}
              {
                [
                  '-',
                  'MapaTecnicos.STATUS_ATIVO',
                  'MapaTecnicos.STATUS_SEM_SINAL',
                  'MapaTecnicos.STATUS_GPS_DESABILITADO',
                  'MapaTecnicos.STATUS_SEM_PERMISSAO',
                ][last_position?.item[0]]
              } */}
            </p>
          </Popup>
        </Marker>
      )
    );
  });
  // console.log(markers);
  return <>{markers}</>;
};

export default MapLayerVeiculos;
