import React, { useEffect } from 'react';
import { Alert, Modal, Toast } from 'react-bootstrap';

import api from '../../../services/api';

// import { Container } from './styles';

const LinkOS: React.FC = ({ src, osId }: { src: string; osId: number }) => {
  const [isShow, invokeModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(undefined);

  useEffect(() => {
    if (isShow)
      api
        .get(`/ispbox/ordensservicos/imprimir/${osId}`)
        .then(res => {
          // console.log(res);
          setModalContent(res.data);
        })
        .catch(err => setModalContent(err));
  }, [isShow]);

  const initModal = () => {
    return invokeModal(!false);
  };

  return (
    <>
      <a
        href={src}
        rel="noreferrer"
        target="_blank"
        onClick={e => {
          e.preventDefault();
          invokeModal(true);
        }}
      >
        {osId}
      </a>
      {isShow && (
        <Modal
          show={isShow}
          scrollable
          centered
          onHide={() => invokeModal(false)}
          keyboard
          size="lg"
          // style={{ width: 900 }}
          // fullscreen
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body dangerouslySetInnerHTML={{ __html: modalContent }} />
        </Modal>
      )}
    </>
  );
};

export default LinkOS;
