import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

import useSWR from 'swr';

import logging from '../../util/logging';
import apiReagendarOS from './apiReagendarOS';

const FormReagendarOS = ({
  data: {
    id,
    status,
    prioridade,
    setores_id,
    tecnico_id,
    hora_inicio,
    hora_fim,
    km,
    servico_executado: descricao,
    data_hora_agendamento,
    data_atendimento,
  },
}: {
  data: {
    id: number;
    status: number;
    prioridade: number;
    setores_id: number;
    tecnico_id: number;
    hora_inicio: string;
    hora_fim: string;
    km: number;
    servico_executado: string;
    data_hora_agendamento: string;
    data_atendimento: string;
  };
}) => {
  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!false);
  };

  const [data_agendamento, hora_agendamento] = data_hora_agendamento.split(' ');

  const initialFormData = {
    id,
    status,
    prioridade,
    setores_id,
    tecnico_id,
    hora_inicio,
    hora_fim,
    km,
    descricao,
    data_agendamento,
    hora_agendamento,
    qualquer_horario_agendamento: hora_agendamento === '00:00:00' ? '1' : '0',
    data_atendimento: data_atendimento.split('T')[0],
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = event => {
    logging.info(
      'handleChange -> ' + event.target.name + ' : ' + event.target.value,
      'FormReagendarOS'
    );

    setFormData(formData => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    const response: any = await apiReagendarOS(formData);
    // return (
    //   // <div className="p-3" position="top-end" style={{ zIndex: 1 }}>
    //   <Toast>
    //     <Toast.Header closeButton={true}>
    //       <strong className="me-auto">Bootstrap</strong>
    //       <small>11 mins ago</small>
    //     </Toast.Header>
    //     <Toast.Body>
    //       ✅ Ordem de Serviço {id} atualizada {response?.erro}
    //     </Toast.Body>
    //   </Toast>
    //   // {/* </div> */}
    //   // <Alert variant="success">
    //   //   ✅ Ordem de Serviço {id} atualizada {response?.erro}
    //   // </Alert>
    // );

    if (response.erro === 0) {
      // alert(`✅ Ordem de Serviço ${id} atualizada`);
      invokeModal(false);
    } else {
      logging.error(
        `❌ Erro ao atualizar OS ${id}: ${response.erro}`,
        'FormReagendarOS'
      );
      alert(`❌ Erro ao atualizar OS ${id}: ${response.erro}`);
    }
  };

  const { data: tecnicos = [] } = useSWR(
    `${process.env.REACT_APP_API_URL}/boardos/export/usuarios?select=id,nome&per_page=100&order_by=habilitado DESC, nome ASC`,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fetcher: (resource, init) =>
        api.get(resource, { ...init }).then(res => res.data.data),
    }
  );

  return (
    <>
      {/* <Button variant="secondary" size="sm" onClick={initModal}> */}
      <span
        title="Reagendar/Trocar Responsável"
        style={{
          cursor: 'pointer',
          color: '#989898',
          textShadow: ' 2px 2px 1px 	#D3D3D3',
          float: 'right',
          marginRight: 3,
        }}
        onClick={initModal}
      >
        <i className={`fa fa-calendar-days fa-outline  fa-lg `} />
      </span>
      {/* </Button> */}
      <Modal
        show={isShow}
        scrollable
        centered
        onHide={() => invokeModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reagendar/Trocar Responsavél #{id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="FormOsReagendar" onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Agendamento Atual:</Form.Label>{' '}
              {data_hora_agendamento}
            </Form.Group>
            <Row>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label>Data:</Form.Label>
                <Form.Control
                  type="date"
                  pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                  name="data_agendamento"
                  id="FormDataAgendamento"
                  value={formData.data_agendamento}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label>Hora:</Form.Label>
                <Form.Control
                  type="time"
                  name="hora_agendamento"
                  id="FormHoraAgendamento"
                  alt="time"
                  value={formData.hora_agendamento}
                  onChange={handleChange}
                  disabled={formData.qualquer_horario_agendamento === '1'}
                />
              </Form.Group>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label>Atendimento</Form.Label>{' '}
                {/* <Form.Check
                  name="qualquer_horario_agendamento"
                  id="FormQualquerHorarioAgendamento1"
                  type="switch"
                  label="Hora especifica"
                  defaultChecked={formData.qualquer_horario_agendamento}
                  onChange={handleChange}
                /> */}
                <Form.Check
                  name="qualquer_horario_agendamento"
                  id="FormQualquerHorarioAgendamento0"
                  type="radio"
                  value="0"
                  label="Hora especifica"
                  defaultChecked={formData.qualquer_horario_agendamento === '0'}
                  onChange={handleChange}
                />
                <Form.Check
                  name="qualquer_horario_agendamento"
                  id="FormQualquerHorarioAgendamento1"
                  type="radio"
                  value="1"
                  label="Qualquer hora"
                  defaultChecked={formData.qualquer_horario_agendamento === '1'}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <p>Deixe em branco para remover o agendamento</p>
            <Row>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label>Prioridade:</Form.Label>{' '}
                <Form.Check
                  type="radio"
                  name="prioridade"
                  id="selOsPrioridadeAlta"
                  label="Alta"
                  inline
                  value={0}
                  defaultChecked={formData.prioridade === 0}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  name="prioridade"
                  id="selOsPrioridadeNormal"
                  label="Normal"
                  inline
                  value={1}
                  defaultChecked={formData.prioridade === 1}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  name="prioridade"
                  id="selOsPrioridadeBaixa"
                  label="Baixa"
                  inline
                  value={2}
                  defaultChecked={formData.prioridade === 2}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label htmlFor="FormSetorId">Setor: </Form.Label>{' '}
                <select
                  name="setores_id"
                  id="FormSetorId"
                  onChange={handleChange}
                >
                  <option value="0" selected={formData.setores_id === 0}>
                    Nenhum
                  </option>
                  <option value="1" selected={formData.setores_id === 1}>
                    Administrativo
                  </option>
                  <option value="7" selected={formData.setores_id === 7}>
                    Agendamento
                  </option>
                  <option value="6" selected={formData.setores_id === 6}>
                    Atendimento
                  </option>
                  <option value="8" selected={formData.setores_id === 8}>
                    Cobranças
                  </option>
                  <option value="10" selected={formData.setores_id === 10}>
                    Compras
                  </option>
                  <option value="9" selected={formData.setores_id === 9}>
                    Estoque
                  </option>
                  <option value="4" selected={formData.setores_id === 4}>
                    Financeiro
                  </option>
                  <option value="2" selected={formData.setores_id === 2}>
                    Técnico
                  </option>
                  <option value="3" selected={formData.setores_id === 3}>
                    Vendas
                  </option>
                </select>
              </Form.Group>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label htmlFor="FormTecnicoId">Responsável: </Form.Label>{' '}
                <select
                  name="tecnico_id"
                  id="FormTecnicoId"
                  onChange={handleChange}
                >
                  {[
                    { label: '(Nenhum)', value: 0 },
                    ...tecnicos?.map((item: any) => ({
                      label: item.nome,
                      value: item.id,
                    })),
                  ].map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                      selected={formData.tecnico_id === option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="form-floating ">
                <Form.Label htmlFor="FormStatusNovo">Status: </Form.Label>{' '}
                <Form.Check
                  type="radio"
                  name="status"
                  id="osStatusNovoAberta"
                  label="Aberta"
                  inline
                  value={0}
                  defaultChecked={formData.status === 0}
                  onChange={handleChange}
                  disabled={status !== 0}
                />
                <Form.Check
                  type="radio"
                  name="status"
                  id="osStatusNovoExecutada"
                  label="Executada"
                  inline
                  value={2}
                  defaultChecked={formData.status === 2}
                  onChange={handleChange}
                  disabled={status !== 0}
                />
                <Form.Check
                  type="radio"
                  name="status"
                  id="osStatusNovoCancelada"
                  label="Cancelada"
                  inline
                  value={3}
                  defaultChecked={formData.status === 3}
                  onChange={handleChange}
                  disabled={status !== 0}
                />
                <Form.Check
                  type="radio"
                  name="status"
                  id="osStatusNovoFechada"
                  label="Fechada"
                  inline
                  value={1}
                  defaultChecked={formData.status === 1}
                  onChange={handleChange}
                  // readOnly={status !== 0}
                  disabled
                />
              </Form.Group>
            </Row>
            <div
              id="StatusExecutada"
              style={{
                display:
                  formData.status === 0 || formData.status === 3
                    ? 'none'
                    : 'block',
              }}
            >
              <Form.Group className="form-floating ">
                <Form.Label htmlFor="FormDataAtendimento">
                  Data de Atendimento:
                </Form.Label>
                <Form.Control
                  type="date"
                  pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                  name="data_atendimento"
                  id="FormDataAtendimento"
                  value={formData.data_atendimento}
                  onChange={handleChange}
                />
              </Form.Group>
              <Row>
                <Form.Group as={Col} className="form-floating ">
                  <Form.Label htmlFor="FomrHoraInicio">
                    Hora de Início:
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="hora_inicio"
                    id="FomrHoraInicio"
                    alt="time"
                    value={formData.hora_inicio}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} className="form-floating ">
                  <Form.Label htmlFor="FomrHoraFim">Hora Fim:</Form.Label>
                  <Form.Control
                    type="time"
                    name="hora_fim"
                    id="FomrHoraFim"
                    alt="time"
                    value={formData.hora_fim}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} className="form-floating ">
                  <Form.Label htmlFor="FormKm">KM:</Form.Label>
                  <Form.Control
                    type="number"
                    inputMode="decimal"
                    name="km"
                    id="FormKm"
                    alt="km"
                    placeholder="00.000"
                    value={formData.km}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            </div>
            <Form.Group
              className="form-floating "
              id="StatusDescricao"
              style={{ display: formData.status !== 0 ? 'block' : 'none' }}
            >
              <Form.Label htmlFor="FormStatusDescricao">Descrição:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descricao"
                id="FormStatusDescricao"
                placeholder="descrição completa do serviço"
                defaultValue={formData.descricao}
                onChange={handleChange}
              />
            </Form.Group>
            {/* end form */}
            <Form.Control type="hidden" name="id" value={id} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => invokeModal(false)}>
            Cancelar
          </Button>
          <Button variant="dark" onClick={handleSubmit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormReagendarOS;
