import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import { AxiosRequestConfig } from 'axios';
import { divIcon, LatLngExpression } from 'leaflet';
import useSWR from 'swr';

import api from '../../../services/api';
import toLatLng from '../../../util/parse-coordinates';

const MapLayerTecnicos = () => {
  const options: AxiosRequestConfig = {
    // url: `/ispbox-proxy/ordensservicos/get-tecnicos`,
    // auth: {
    //   username: process.env.REACT_APP_ISPBOX_ADMIN_USERNAME || 'demo',
    //   password: process.env.REACT_APP_ISPBOX_ADMIN_PASSWORD || 'demo',
    // },
  };

  const fetcher = (url: string) =>
    api.get(url, options).then(res => res.data.tecnicos);

  const { data: getTecnicos, error } = useSWR(
    `/ispbox/ordensservicos/get-tecnicos`,
    fetcher,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
      errorRetryInterval: 1000,
      // errorRetryCount: 1,
      shouldRetryOnError: false,
    }
  );

  function getUltimaPosicaoNaoVazia(array: any) {
    if (array === null) return;
    // eslint-disable-next-line eqeqeq
    const item = array.findLast((element: any) => element != '');
    const key = array.findLastIndex(
      // eslint-disable-next-line eqeqeq
      (element: any) => element != ''
    );
    return {
      key: key,
      item: item,
    };
  }
  const markers = getTecnicos?.map((marker: any) => {
    const last_position = getUltimaPosicaoNaoVazia(marker.gps);

    const marker_position: any = toLatLng(
      `${last_position?.item[1] || '0.000001'}, ${
        last_position?.item[2] || '0.000001'
      }`
    ) || ['0.000001', '0.000001'];
    // console.log(
    //   marker_position,
    //   last_position,
    //   `${last_position?.item[1]}, ${last_position?.item[2]}`
    // );
    return (
      marker_position && (
        <Marker
          icon={divIcon({
            html: renderToStaticMarkup(
              <i
                className={`fa fa-user fa-3x`}
                style={{
                  color: [
                    '#8a02d8', // '-',
                    '#0dd802', // 'MapaTecnicos.STATUS_ATIVO',
                    '#a19ba5', // 'MapaTecnicos.STATUS_SEM_SINAL',
                    '#bc0000', // 'MapaTecnicos.STATUS_GPS_DESABILITADO',
                    '#d8cd02', // 'MapaTecnicos.STATUS_SEM_PERMISSAO',
                  ][last_position?.item[0]],
                  // border: '3px solid red',
                  // textShadow: ' 2px 2px 4px #f0ad4e',
                }}
              />
            ),
          })}
          key={`TecnicoGPS_${marker.id}`}
          position={marker_position}
          // title={`Técnico ${marker.id}`}
          alt={`Técnico ${marker.id}`}
        >
          <Tooltip>
            {marker.id} - {marker.nome} ({marker.login})
          </Tooltip>
          <Popup>
            <strong>
              {marker.nome} - {marker.login}
            </strong>
            <br />
            <small>
              {marker.cargo}
              {marker.revendas !== '-' && ` - ${marker.revendas}`}
            </small>
            <p>
              Última atualização: {marker.data_atualizacao}
              <br />
              Coordenadas:{' '}
              <a
                href={`http://www.google.com/maps/place/${last_position?.item[1]},${last_position?.item[2]}`}
                target="blank"
              >
                ({last_position?.item[1]},{last_position?.item[2]})
              </a>
              <br />
              Status:{' '}
              {
                [
                  '-',
                  'MapaTecnicos.STATUS_ATIVO',
                  'MapaTecnicos.STATUS_SEM_SINAL',
                  'MapaTecnicos.STATUS_GPS_DESABILITADO',
                  'MapaTecnicos.STATUS_SEM_PERMISSAO',
                ][last_position?.item[0]]
              }
            </p>
          </Popup>
        </Marker>
      )
    );
  });
  // console.log(markers);
  return <>{markers}</>;
};

export default MapLayerTecnicos;
