import React, { useState, useEffect, useRef } from 'react';
import {
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Navbar,
  Button,
  Nav,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

import { SubmitHandler } from '@unform/core';

import { useAuth } from '../../contexts/auth';
import FormOS from './SearchOS';

// import { Profile, Logout } from './styles';

// import useSWR, { SWRConfig } from 'swr';

function SearchBar({
  // data,
  panels,
  handleSwitchBoard,
  handleSwitchView,
  // resultList,
  searchQuery,
}: Record<string, never> | any) {
  const { signOut, user } = useAuth();
  const [initialSearchQuery, setSearchQuery] = searchQuery;
  const [activeData, setActiveData] = useState({});
  const [activePanel, setActivePanel] = useState(
    panels.filter((p: any) => p.active)[0]
  );

  const viewIcons = {
    kanban: 'fa-brands fa-trello',
    map: 'fa-duotone fa-map-location-dot',
  };

  useEffect(() => {
    setActivePanel(panels.filter((p: any) => p.active)[0]);
    setActiveData(activePanel.filterFixed);
  }, [activePanel, panels]);

  const submitHandler: SubmitHandler<FormData> = data => {
    setSearchQuery({ ...activeData, ...data });
  };

  return (
    <Navbar bg="light" expand="lg">
      {/* <Navbar.Brand href="/">BoardOS</Navbar.Brand> */}
      <ButtonToolbar aria-label="Toolbar with Button groups">
        <DropdownButton
          title={activePanel.name}
          id="bg-nested-dropdown"
          variant="secondary"
          // onSelect={handleSwitchBoard}
        >
          {panels.map((panel: any, index: number) => (
            <DropdownItem
              key={index}
              // eventKey={panel.component}
              onClick={() => handleSwitchBoard(panel)}
            >
              {panel.name}
            </DropdownItem>
          ))}
          <Dropdown.Divider />
          <DropdownItem>
            <div>
              <div className="outline-danger  float-left">
                {user?.name}
                {'  '}
              </div>
              <Button onClick={signOut} variant="outline-danger float-right">
                Sair
              </Button>
            </div>
          </DropdownItem>
        </DropdownButton>

        {/* <ButtonGroup
          title={activePanel.name}
          id="bg-nested-button-views"
          // variant="secondary"
          // onSelect={handleSwitchBoard}
        >
          {activePanel.viewMode.map((view: any, index: number) => (
            <ToggleButton
              type="checkbox"
              name={view.type}
              value={view.active}
              checked={view.active}
              key={index}
              // eventKey={panel.component}
              onClick={() => handleSwitchView(view)}
            >
              <i
                // eslint-ignore
                // className={`fa ${viewIcons[view.type.toLowerCase()]} fa-1x`}
                style={{ background: 'transparent', border: 'none' }}
              ></i>
            </ToggleButton>
          ))}
        </ButtonGroup> */}
        {/* {'   '}
        <InputGroup className="mr-sm-2" style={{ marginLeft: 10 }}>
          <InputGroup.Prepend>
            <InputGroup.Text id="btnGroupAddonOS">OS #</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="number"
            placeholder="Cód OS"
            aria-label="Cód OS"
            aria-describedby="btnGroupAddonOS"
            value={searchTermId}
            onChange={handleSearchOSid}
            style={{ width: 100 }}
          />
        </InputGroup> */}
      </ButtonToolbar>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {/* <ButtonToolbar aria-label="Toolbar with Search groups"> */}
          <FormOS
            initialData={initialSearchQuery}
            onSubmit={submitHandler}
            activeData={activeData}
          />
          {/* </ButtonToolbar> */}
        </Nav>
      </Navbar.Collapse>
      {/*
      <Profile>
        <div>
          <strong>{user?.name}</strong>
          <Link to="/profile">Meu perfil</Link>
        </div>
        <Logout onClick={signOut}>Sair</Logout>
      </Profile> */}
    </Navbar>
  );
}

export default SearchBar;
