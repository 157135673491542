import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  LayersControl,
  LayerGroup,
  Tooltip,
} from 'react-leaflet';

import { divIcon, LatLngExpression } from 'leaflet';

import LeafletGeoSearch from '../../components/LeafletGeoSearch';
import toLatLng from '../../util/parse-coordinates';
import CardDescription from './components/CardDescription';
import MapLayerTecnicos from './components/MapLayerTecnicos';
import MapLayerVeiculos from './components/MapLayerVeiculos';

function MapOS({ data: dt }: { data: any }) {
  const data = dt;

  // const [map, setMap] = useState<L.Map | null>(null);

  const position: LatLngExpression = [-28.0700417, -48.6560278];

  const dynMarkerIcon = (os: any) => {
    const iconColor = ['#3e4243', '#0275d8', '#5cb85c', '#d9534f'][os.status];
    const iconSize = ['fa-3x', 'fa-1x', 'fa-2x', 'fa-2x'][os.status];
    const iconBorder = [
      ' 2px 2px 4px #f0ad4e',
      ' 2px 2px 4px #5cb85c',
      ' 2px 2px 4px #5bc0de',
    ][os.prioridade];
    const iconName =
      os.data_hora_agendamento !== '0000-00-00 00:00:00'
        ? 'fa-map-marker'
        : 'fa-map-marker-alt';

    return divIcon({
      html: renderToStaticMarkup(
        <span style={{ color: iconColor, textShadow: iconBorder }}>
          <i className={`fa ${iconName} ${iconSize} `} />
        </span>
      ),
    });
  };
  return (
    <MapContainer
      center={position}
      zoom={12}
      scrollWheelZoom={true}
      zoomControl={false}
      // whenCreated={setMap}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="OpenStreetMap.Mapnik" checked>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google.Satelite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="ArcGIS">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay key="OS" name="Ordens de Serviço" checked>
          <LayerGroup>
            {data?.map((marker: any) => {
              const marker_position: any = toLatLng(marker.gps_coordenadas);

              return (
                marker_position && (
                  <Marker
                    icon={dynMarkerIcon(marker)}
                    key={`OS_${marker.id}`}
                    position={marker_position}
                    // title={`OS ${marker.id}`}
                    alt={`OS ${marker.id}`}
                  >
                    <Tooltip>
                      {marker.id}-{marker.nome}
                    </Tooltip>
                    <Popup>
                      <strong>
                        {marker.ordens_servicos_tipo} - OS {marker.id}
                      </strong>
                      <CardDescription data={marker} />
                      <hr />
                      <span>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${process.env.REACT_APP_ISPBOX_ADMIN_URL}/ordensservicos/imprimir/${marker.id}/visualizar:1`}
                        >
                          Ver OS
                        </a>
                      </span>
                    </Popup>
                  </Marker>
                )
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay key="TecnicosGPS" name="Técnicos" checked>
          <LayerGroup>
            <MapLayerTecnicos />
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay key="VeiculosGPS" name="Veículos" checked>
          <LayerGroup>
            <MapLayerVeiculos />
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <ZoomControl position="topright" />
      <LeafletGeoSearch />
    </MapContainer>
  );
}

export default MapOS;
