import React from 'react';
import TimeAgo from 'react-timeago';

import FormReagendarOS from '../../../components/FormReagendarOS';
import InputAnotacao from '../../../components/InputAnotacao';

// import brazillianStrings from 'react-timeago/lib/language-strings/pt-br'
// import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

// const formatter = buildFormatter(brazillianStrings)
const osOrigemTipoNome: Record<string, never> | any = {
  CLIENTE: 'Cliente',
  INTERNET: 'Cliente',
  TELEFONIA: 'Cliente',
  POP: 'POP',
  POP_ELEMENTO: 'POP Elemento',
};
const CardDescription = ({ data }: { data: any }) => (
  <>
    <div>
      <span>
        {osOrigemTipoNome[data.origem_tipo]}: {data.nome}
      </span>
      <br />
      {/* <span>
        Origem:{' '}
        <a target="_blank" rel="noopener noreferrer" href={data.link_cad}>
          {data.origem_tipo} ( {data.origem_id} )
        </a>
      </span>
      <br /> */}
      <span>Bairro: {data.bairro}</span>
      <br />
      <span>
        Aberto: <TimeAgo date={data.data_hora_abertura} />
      </span>
      <br />
      {data.status === 0 &&
        data.data_hora_agendamento !== '0000-00-00 00:00:00' && (
          <>
            <span>
              Agendamento: <TimeAgo date={data.data_hora_agendamento} />
            </span>
            <br />
          </>
        )}
      {/* {data.status === 1 && (
        <>
          <span>
            Fechamento: <TimeAgo date={data.data_hora_fechamento} />
          </span>
          <br />
        </>
      )}
      {data.status === 2 && (
        <>
          <span>
            Executado: <TimeAgo date={data.data_hora_fechamento} />
          </span>
          <br />
        </>
      )} */}
      {data.status !== 0 &&
        data.data_hora_fechamento !== '0000-00-00 00:00:00' && (
          <>
            <span>
              {['Aberto', 'Fechado', 'Executado', 'Cancelado'][data.status]}:{' '}
              <TimeAgo date={data.data_hora_fechamento} />
            </span>
            <br />
          </>
        )}
      <span>Técnico: {data.tecnico_nome || 'Não alocado'}</span>{' '}
      <FormReagendarOS data={data} /> <br />
      {/*
      <span>Status: {data.status || "Aberta"} </span>
      <span>Prioridade: {data.prioridade}</span> <br /> */}
      <span>
        <b>Anotação: </b>
        <InputAnotacao id={data.id} anotacao={data.anotacao_agendamento} />
      </span>
      <br /> <br />
    </div>
  </>
);

export default CardDescription;
