import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import api from '../services/api';

interface IUser {
  name?: string;
  username?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
}

interface AuthContextData {
  isAuthenticated: boolean;
  user: IUser | null;
  isLoading: boolean;
  signIn(user: IUser): Promise<void>;
  signUp(user: IUser): Promise<void>;
  signOut(): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type Props = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storagedUser = localStorage.getItem('@BoardOS:user');
    const storagedToken = localStorage.getItem('@BoardOS:token');

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, []);

  const signIn = async (userData: { username: string; password: string }) => {
    setIsLoading(true);
    // console.log(userData);
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/login`,
        userData
      );
      console.log(response);

      if (response.data.token) {
        api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
        localStorage.setItem(
          '@BoardOS:user',
          JSON.stringify(response.data.user)
        );
        localStorage.setItem('@BoardOS:token', response.data.token);
        setUser(response.data.user);
        setIsAuthenticated(true);
        setIsLoading(false);
        return history?.push('/');
      }

      setIsLoading(false);
      return history?.push('/');
    } catch (error) {
      setIsLoading(false);
      // history.push('/');
      throw error;
    }
  };

  const signUp = async (userData: IUser) => {
    setIsLoading(true);

    const response = await api.post(
      `${process.env.REACT_APP_API_URL}/register`,
      userData
    );

    if (response.data.token && response.data.user) {
      setUser(response.data.user);
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

      localStorage.setItem('@BoardOS:user', JSON.stringify(response.data.user));
      localStorage.setItem('@BoardOS:token', response.data.token);
    }

    setIsLoading(false);
    history?.push('/login');
  };

  const signOut = async () => {
    setIsLoading(true);
    setIsAuthenticated(false);
    setUser(null);
    api.get(`${process.env.REACT_APP_API_URL}/logout`);
    api.defaults.headers.Authorization = undefined;
    localStorage.clear();
    setIsLoading(false);
    history?.push('/');
  };

  // if (isLoading) return <h1>Carregando...</h1>;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => useContext(AuthContext);
