import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { useAuth } from '../contexts/auth';
import BoardOSDashBoard from '../pages/BoardOS';
import SignIn from '../pages/SignIn';
import { RouteWrapper as Route } from './route';

// import { Container } from './styles';

const Routes: React.FC = () => {
  const { signOut } = useAuth();
  return (
    <Router>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route
          path="/logout"
          component={() => {
            signOut();
            return <Redirect to="/" />;
          }}
        />
        {/* <Route path="/register" component={SignUp} /> */}
        <Route isPrivate path="/" exact component={BoardOSDashBoard} />
        <Route path="/404" component={() => <strong>404 Not Found</strong>} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default Routes;
