import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  Tooltip,
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  LayersControl,
  LayerGroup,
  useMap,
} from 'react-leaflet';

import L, { LatLngExpression } from 'leaflet';
// import useSWR, { SWRConfig } from 'swr';

// import useSWR from 'swr';

import ctoIcon from '../../assets/icon_cto.png';
import ispboxIcon from '../../assets/icon_ispbox.png';
import smartoltIcon from '../../assets/icon_smartolt.png';
import LeafletGeoSearch from '../../components/LeafletGeoSearch';
import toLatLng from '../../util/parse-coordinates';
import InternetOnline from './components/InternetOnline';
import InternetSignal from './components/InternetSignal';
import MapLayerTecnicos from './components/MapLayerTecnicos';
import MapLayerVeiculos from './components/MapLayerVeiculos';

function MapCTO({ data: cto }: { data: any }) {
  const position: LatLngExpression = [-28.0700417, -48.6560278];
  const iconMarkup = renderToStaticMarkup(
    <span style={{ color: '#222' }}>
      <i className=" fa fa-cube fa-2x " />
    </span>
  );

  const customMarkerIcon = L.icon({
    iconUrl: ctoIcon,
    iconSize: [16, 25],
    // shadowUrl: iconShadow
  });

  // const customMarkerIcon = divIcon({
  //   html: iconMarkup,
  // });

  return (
    <MapContainer
      center={position}
      zoom={12}
      scrollWheelZoom={true}
      zoomControl={false}
    >
      <LeafletGeoSearch />
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="OpenStreetMap.Mapnik" checked>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google.Satelite">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="ArcGIS">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay key="CTO" name="Caixas de Atendimento" checked>
          <LayerGroup>
            {cto?.map((marker: any) => {
              const marker_position: any = toLatLng(marker.coordenadas);

              // console.log(Object.keys(marker), marker_position);

              return (
                marker_position && (
                  <Marker
                    key={`CTO_${marker.id}_${marker.caixas_atendimento_cabos_id}`}
                    position={marker_position}
                    // title={marker.nome}
                    alt={`CTO ${marker.nome}`}
                    icon={customMarkerIcon}
                  >
                    <Tooltip>{marker.nome}</Tooltip>
                    <Popup>
                      <strong>
                        {marker.id} - {marker.nome}
                      </strong>
                      <br />
                      <span title="OLT/PON">
                        OLT/PON:
                        {marker.pops_elementos_nome
                          ? ` ${marker.pops_elementos_nome}_${marker.pops_elementos_interfaces_nome}`
                          : ' Não cadastrado'}
                      </span>
                      <br />
                      <span title="Portas livres/total">
                        Portas Disponíveis:
                        {marker.portas_total
                          ? ` ${marker.portas_disponiveis}/${marker.portas_total}`
                          : ' Cabo não cadastrado'}
                      </span>
                      <br />
                      <span>Clientes:</span> <br />
                      <ul className="cto-client-list">
                        {marker.portas_clientes
                          ?.split(' | ')
                          .map((cli: string, index: string) => {
                            // if (cli.length < 3) return <></>;
                            return (
                              <li key={`${index}_${cli}`}>
                                <div className="client">
                                  <div>
                                    <span>{cli.split(': ')[0]}</span>
                                    <span>{cli.split(': ')[1]}</span>
                                  </div>
                                </div>
                                <div className="internet">
                                  <div>
                                    <span>
                                      <a
                                        // key={`a_ib_${cli}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="ISPBOX"
                                        href={`${
                                          process.env.REACT_APP_ISPBOX_ADMIN_URL
                                        }/clientes/consultar/${cli.replace(
                                          'Cli: ',
                                          ''
                                        )}`}
                                      >
                                        <img
                                          src={ispboxIcon}
                                          alt="ISPBOX"
                                          width="24px"
                                        />
                                      </a>
                                    </span>
                                    <InternetOnline
                                      serviceId={+cli.split('-')[1]}
                                    />
                                  </div>
                                </div>

                                <div className="onu">
                                  <div>
                                    <span>
                                      <a
                                        // key={`a_solt_${cli}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="SmartOLT"
                                        href={`${
                                          process.env.REACT_APP_SMARTOLT_URL
                                        }/onu/configured?free_text=-${cli
                                          .split('-')[1]
                                          .padStart(4, '0')}`}
                                      >
                                        <img
                                          src={smartoltIcon}
                                          alt="SmartOLT"
                                          width="24px"
                                        />
                                      </a>
                                    </span>
                                    <InternetSignal
                                      serviceId={+cli.split('-')[1]}
                                    />
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </Popup>
                  </Marker>
                )
              );
            })}
          </LayerGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay key="TecnicosGPS" name="Técnicos" checked>
          <LayerGroup>
            <MapLayerTecnicos />
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay key="VeiculosGPS" name="Veículos" checked>
          <LayerGroup>
            <MapLayerVeiculos />
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <ZoomControl position="topright" />
    </MapContainer>
  );
}

export default MapCTO;
