import React from 'react';
import Board from 'react-trello';

import apiReagendarOS from '../../components/FormReagendarOS/apiReagendarOS';
import groupBy from '../../util/groupBy';
import CardDescription from './components/CardDescription';
import LinkOS from './components/LinkOS';
interface Props {
  data: any;
  boardConfig: Record<string, never> | any;
}
const osOrigemTipoLabel: Record<string, never> | any = {
  CLIENTE: 'Cli',
  INTERNET: 'SI',
  TELEFONIA: 'ST',
  POP: 'POP',
  POP_ELEMENTO: 'POP Ele',
};

const BoardOSGroupBy: React.FC<Props> = ({ data: dt, boardConfig }: Props) => {
  const data = groupBy(dt, boardConfig.fieldGroup) || [];

  const moveOSTecnicoId = (fromLaneId, toLaneId, cardId, index) => {
    // if (fromLaneId === toLaneId) return;

    const {
      id,
      status,
      prioridade,
      setores_id,
      tecnico_id,
      hora_inicio,
      hora_fim,
      km,
      servico_executado: descricao,
      data_hora_agendamento,
      data_atendimento,
    } = dt.filter(os => os.id === parseFloat(cardId.split('_')[1]))[0];

    const [data_agendamento, hora_agendamento] = data_hora_agendamento.split(
      ' '
    );

    const initialData = {
      id,
      status,
      prioridade,
      setores_id,
      tecnico_id,
      hora_inicio,
      hora_fim,
      km,
      descricao,
      data_agendamento,
      hora_agendamento,
      qualquer_horario_agendamento: hora_agendamento === '00:00:00' ? '1' : '0',
      data_atendimento: data_atendimento.split('T')[0],
    };

    // set new tecnico_id
    initialData.tecnico_id = toLaneId;

    apiReagendarOS(initialData);
  };

  const cardOS = (data: Array<any> = [], config = { draggable: false }) =>
    data.map((os: any) => {
      const tags: Array<Record<string, unknown>> = [];

      if (os.prioridade === 0 || os.prioridade === 2)
        tags.push({
          title: ['Prioritário', 'Normal', 'Baixo'][os.prioridade],
          bgcolor: ['#f0ad4e', '#5cb85c', '#5bc0de'][os.prioridade],
        });

      // if (os.status in ['1', '2', '3', '0'])
      tags.push({
        title: ['Aberta', 'Fechada', 'Executada', 'Cancelada'][os.status],
        bgcolor: ['#3e4243', '#0275d8', '#5cb85c', '#d9534f'][os.status],
      });

      return {
        id: `OS_${os.id}`,
        title:
          os[boardConfig.fieldCardTitle || 'tecnico_nome'] || 'Não Alocado',
        description: <CardDescription data={os} />,
        label: (
          <>
            OS <LinkOS src={os.link_os} osId={os.id} />
            <br />
            {osOrigemTipoLabel[os.origem_tipo]}{' '}
            <a
              href={os.link_cad}
              title="Cadastro"
              target="_blank"
              rel="noreferrer"
            >
              {os.origem_id}
            </a>
          </>
        ),
        tags,
        draggable: config.draggable && os.status === 0,
      };
    });

  const lanes = {
    lanes: Object.keys(data).map((lane: any) => {
      return {
        id: lane,
        title: data[lane][0][boardConfig.fieldLaneTitle] || 'Não Alocado',

        label: `${data[lane].length}`,
        cards:
          cardOS(data[lane], {
            draggable: boardConfig.fieldGroup === 'tecnico_id',
          }) || [],
      };
    }),
  };

  return (
    <Board
      data={lanes}
      hideCardDeleteIcon
      editLaneTitle={false}
      draggable
      // editable // quebra o card formato Object
      onCardMoveAcrossLanes={moveOSTecnicoId}
    />
  );
};

export default BoardOSGroupBy;
